<template>
    <div class="page-recieve">
        <headeBar title="奖品领取确认" left-arrow @click-left="newAppBack" />
        <div class="records-model">
            <div class="records-img">
                <div class="n">
                    <img :src="rewardObj.rewardImg" alt="" />
                </div>
            </div>
            <div class="records-info">
                {{ rewardObj.rewardName }}
            </div>
        </div>
        <div class="label">领取门店</div>
        <div class="vbox" @click="show">
            <div>{{ venueObJ && venueObJ.label || '请选择前往领取的门店' }}</div>
            <van-icon name="arrow" />
        </div>
        <div class="fb">
            <div class="t1">* 确认领取门店后不可修改</div>
            <div class="sub" :class="{ gray: !venueObJ }" @click="sure">确认领取</div>
            <div class="iphonex-pb"></div>
        </div>
        <CityVenue ref="refCityVenue" :cityId="cityId" @setVenue="setVenue" />
    </div>
</template>
<script>
import { newAppBack, initBack } from '@/lib/appMethod';
import userMixin from '@/mixin/userMixin';
import headeBar from '@/components/app/headBar';
import CityVenue from "@/components/cl-city-venue/cityVenue.vue";

export default {
    components: {
        headeBar,
        CityVenue
    },
    mixins: [userMixin],
    data() {
        return {
            rewardObj: {},
            venueObJ: null
        }
    },
    methods: {
        newAppBack,
        show() {
            this.$refs.refCityVenue.popShow = true;
        },
        setVenue(item) {
            this.venueObJ = item;
            console.log(item)
        },
        async check() {
            return await this.$axios.post(`${this.baseURLApp}/anniversary/limited/check`, {
                userId: this.userId,
                token: this.token,
                activityNo: this.$route.query.activityNo
            }).then((res) => {
                return true
            }).catch((err) => {
                return false
            })
        },
        async sure() {
            if (!this.venueObJ.id) {
                this.$toast('请选择门店')
                return
            }
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                loadingType: 'spinner',
            })
            if (this.$route.query.participateId) {
                this.$axios.post(`${this.baseURLApp}/anniversary/taskGift/awarding`, {
                    userId: this.userId,
                    participateId: this.$route.query.participateId,
                    venueId: this.venueObJ.id,
                    venueName: this.venueObJ.label,
                    token: this.token
                }).then((res) => {
                    this.$toast('领取成功');
                    newAppBack();
                })
            } else {
                let check = await this.check();
                if (check) {
                    this.$axios.post(`${this.baseURLApp}/anniversary/limited/receive`, {
                        userId: this.userId,
                        token: this.token,
                        activityNo: this.$route.query.activityNo,
                        venueInfo: {
                            venueId: this.venueObJ.id,
                            venueName: this.venueObJ.label
                        }
                    }).then((res) => {
                        this.$toast('领取成功');
                        newAppBack();
                    })
                }
            }

        }
    },
    async created() {
        console.log(1234)
        await this.$getAllInfo(['userId', 'cityId']);
        if (this.$route.query.participateId) {
            const res = await this.$axios.post(this.baseURLApp + "/anniversary/taskGift/queryRewardById", {
                hasWx: true,
                userId: this.userId,
                participateId: this.$route.query.participateId
            })
            this.rewardObj = res.data;
        } else {
            this.rewardObj = {
                rewardImg: 'http://img.chaolu.com.cn/88activity/jp.png',
                rewardName: '全民健身季奖牌',
                activityNo: this.$route.query.activityNo
            }
        }

    }
}
</script>
<style lang="less" scoped>
.page-recieve {
    position: absolute;
    height: 100vh;
    top: 0;
    // bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;

    div {
        box-sizing: border-box;
    }

    img {
        vertical-align: top;
        width: 100%;
    }


    .records-model {

        .records-img {
            width: 200px;
            height: 200px;
            background: linear-gradient(0deg, #FFB51D 0%, #F9624E 100%);
            margin-right: 40px;
            border-radius: 24px;
            padding: 8px;
            margin: 48px auto 19px;

            .n {
                background-color: #FFF5E9;
                border-radius: 16px;
                border: 1px solid #EEEEEE;
                height: 184px;
            }

            img {
                height: 100%;
            }
        }

        .records-info {
            font-size: 30px;
            font-weight: bold;
            color: #242831;
            text-align: center;
        }

        .records-tips {
            font-size: 22px;
            color: #666;
            line-height: 34px;
        }
    }

    .label {
        font-size: 26px;
        color: #6C727A;
        margin-left: 75px;
        margin-bottom: 21px;
        margin-top: 150px;
    }

    .vbox {
        margin: 0 45px;
        height: 100px;
        background: #F5F5F5;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        font-size: 30px;
    }

    .fb {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 32px;

        .t1 {
            color: #242831;
            font-size: 24px;
            text-align: center;
            margin-bottom: 40px;
        }

        .sub {
            border-radius: 8px;
            line-height: 96px;
            color: #242831;
            font-size: 32px;
            background: #FFDE00;
            text-align: center;
        }

        .gray {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}
</style>
